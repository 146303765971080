<template>
    <div class="page-rooter-fff">
        <nav-bar header-title="青少年体育全项提升" backColor="#ffffff" :show-back="true" :header-back="newAppBack"/>
        <app-page id="box" class="page-img">
            <div class="my-swipe" style="min-height: 50px">
                <img v-if="showImg" @error="errorFun" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/new-people-2022/wx/top_bg.jpg" alt="">
            </div>
            <div class="example-3d swiper">
                <van-collapse v-model="activeNames" @change="onChange">
                    <van-collapse-item :value="showDistance? v.distanceStr: ''" :title="v.title" :name="k" v-for="(v, k) in storeTeacherList" :key="v.id">
                        <div class="swiper-slide">
                            <div class="swiper-content coach">
                                <div class="coach-box">
                                    <div class="coach-list no-swiping" v-if="v.coachList && v.coachList.length">
                                        <div @click="jumpCoachDetail(item.teacherId)" class="coach-item" v-for="(item, index) in v.coachList" :key="index">
                                            <img :src="dealUrl(item.halfImg)" alt="" @error="errorFun">
                                            <div class="coach-name"><div>{{ item.name }}</div><span>PT{{ item.rating }}</span></div>
                                            <div class="coach-tab" v-if="item.labelList.length">
                                                <span v-for="(v) in item.labelList" :key="v" class="ellipsis">{{ v }}</span>
                                            </div>
                                            <div class="coach-num">累计上课{{ item.classNum }}节</div>
                                            <div class="coach-price">
                                                <div>
                                                    <span class="s1">¥</span>
                                                    <span class="s2">{{ item.priceType === '1' ? item.price : item.firstTeacherPrice }}</span>
                                                    <span class="s3">/节</span>
                                                </div>
                                                <p v-if="(item.priceType === '1' && item.discountLabel) || item.priceType!=='1'" class="ellipsis">{{ item.priceType === '1' ? item.discountLabel : '首节体验特惠' }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else class="empty">
                                        <img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/WeChat/defaultPic/empty1.png" alt="">
                                        <div>鹿团团正在努力排课中 <br> 亲爱的超鹿会员请稍等</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </van-collapse-item>
                </van-collapse>
            </div>
        </app-page>
    </div>
</template>

<script>
import {newAppBack, initBack, gotoAppPage, gotoVenue, gotoCoachDetail, appGetDevice, webAppShare, closeWindow} from '@/lib/appMethod';
import navBar from "@/components/nav-bar/nav-bar";
import appPage from "@/common/components/appPage";
import userMixin from '@/mixin/userMixin';
export default {
    components: {
        navBar,
        appPage,
    },
    mixins: [userMixin],
    data() {
        return {
            storeTeacherList: [],  // 门店列表
            coachList: [],
            venueId: ['1'],
            showDistance: false,
            showImg: true,
            activeNames: [0, 1],
            venueName: '中庚青年广场店',
            coachInfo: {}, //教练信息
        };
    },
    mounted() {
        document.addEventListener("visibilitychange", () => {
            if (document.visibilityState === "visible") {
                if(this.userId && this.token){

                }
            }else if(document.visibilityState === "hidden"){
                this.showShare = false
                this.isShareWx = false
                this.showReward = false
            }
        })
    },
    methods: {
        newAppBack,
        // 获取列表并请求门店下教练
        queryStoreList() {
            const that = this;
            this.$axios.post(`${this.baseURLApp}/scheduleLesson/queryStoreList/v2`, {
                areaId: '',
                "cityId": this.currentCityId,
                page: "1",
                rows: "100",
                projectId: "",
                lng: this.local?.lng ?? null,
                lat: this.local?.lat ?? null,
            }).then(res => {
                let infoData = res.data || []
                infoData.forEach(v => {
                    if(v.distance < 100){
                        v.distanceStr = '小于100m'
                    }else if(v.distance < 1000) {
                        v.distanceStr = parseInt(v.distance || '0') + 'm'
                    }else{
                        v.distanceStr = (Math.floor(v.distance / 100) / 10) + 'km'
                    }
                })
                this.storeTeacherList = JSON.parse(JSON.stringify(infoData));
                this.storeTeacherList.forEach((item, index) => {
                    if(index < 2){
                        this.queryCoachList(item.id, index)
                    }
                })
            })
        },
        onChange(v) {
            console.log(v)
            if(v.length){
                v.forEach(item => {
                    if (this.storeTeacherList[item].coachList && this.storeTeacherList[item].coachList.length > 0) {
                        return false
                    }else{
                        this.queryCoachList(this.storeTeacherList[item].id, item)
                    }
                })
            }
        },
        queryCoachList(venueId, index) {
            this.$axios.post(`${this.baseURLApp}/pt/getVenuePerTeacherList`, {
                page: 0,
                rows: 0,
                venueId,
                autoVoucher: 0,
                compatible: 1,
                userId: this.userId,
                selList:[{"selName":"私教课程(单选)","paramName":"project","multiSelect":"0","colsNum":4,"paramList":[{"content":"青少年体育全项提升","type":"44","active":true}]}]
            }).then(res => {
                let coachList = res.data || []
                coachList.map(v => {
                    v.labelList = v.labelList.slice(0, 4)
                    return v
                })
                this.storeTeacherList[index].coachList = coachList
                this.$forceUpdate()
            })
        },
        // 跳转教练
        jumpCoachList() {
            gotoAppPage(1, this.venueId.length > 0? this.venueId[0] : 1)
        },
        // 跳转教练
        jumpCoachDetail(v) {
            gotoCoachDetail(v)
        },
        dealUrl(v){
            if (!v || v.indexOf('http') < 0) {
                return ('http://img.chaolu.com.cn'+v);
            } else {
                return v;
            }
        },
        // 处理图片加载失败时间
        errorFun(e){
            try {
                this.showImg = false
            }catch (e){
                console.log('图片处理报错')
            }
        }
    },
    async created() {
        initBack();
        await this.$getUserId();
        this.currentCityId = await this.$getCityId();
        let cityId = this.$route.query.cityId
        await this.$getLocal();
        this.currentCityId = this.currentCityId? this.currentCityId: cityId? cityId: '3'
        this.queryStoreList()
        if(this.local?.lng && this.local?.lat){
            this.showDistance = true
        }
    },
};
</script>

<style scoped lang="less">
.page-rooter-fff {
    background: #f5f5f5;
    min-height: 100vh;
}
/deep/ .van-collapse-item__content{
    background-color: #f5f5f5;
}
/deep/ .van-cell__title{
    span{
        font-weight: bold;
    }
}
.swiper {
    width: 100vw;
    box-sizing: border-box;
    .swiper-slide {
        width: 100%;
        box-sizing: border-box;
        .swiper-content{
            box-sizing: border-box;
            width: 100%;
            background-color: #f5f5f5;
            border-radius: 16px;
            position: relative;
            .self-img{
                margin-bottom: 60px;
                img{
                    width: 100%;
                    border-radius: 16px;
                }
            }
            .coach-box{
                .coach-list{
                    display: flex;
                    flex-wrap: wrap;
                    &::-webkit-scrollbar{
                        display: block;
                    }
                    .coach-item{
                        flex-shrink: 0;
                        width: 330px;
                        background: #F9FAFB;
                        border-radius: 16px;
                        overflow: hidden;
                        padding-bottom: 30px;
                        margin-right: 26px;
                        margin-bottom: 16px;
                        &:nth-child(2n){
                            margin-right: 0;
                        }
                        img{
                            width: 330px;
                            height: 330px;
                            display: block;
                            object-fit: cover;
                        }
                        .coach-name{
                            display: flex;
                            align-items: center;
                            padding: 0 16px;
                            margin-top: 24px;
                            div{
                                font-size: 28px;
                                font-weight: bold;
                                color: #242831;
                                line-height: 28px;
                            }
                            span{
                                width: 42px;
                                height: 28px;
                                background: #232733;
                                border-radius: 4px;
                                font-size: 16px;
                                color: #FFFFFF;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                margin-left: 12px;
                            }
                        }
                        .coach-tab{
                            padding: 0 16px;
                            display: flex;
                            align-items: center;
                            margin-top: 24px;
                            span{
                                height: 32px;
                                width: 52px;
                                flex-grow: 0;
                                flex-shrink: 0;
                                background: #EEEEEE;
                                border-radius: 4px;
                                font-size: 18px;
                                color: #3C454E;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                margin-left: 8px;
                                &:first-child{
                                    margin-left: 0;
                                }
                            }
                        }
                        .coach-num{
                            padding: 0 16px;
                            color: #3C454E;
                            line-height: 18px;
                            font-size: 18px;
                            margin-top: 24px;
                        }
                        .coach-price{
                            margin-top: 14px;
                            padding: 0 16px;
                            display: flex;
                            align-items: flex-end;
                            div{
                                flex-shrink: 0;
                                position: relative;
                                top: 10px;
                                .s1, .s2, .s3{
                                    font-size: 22px;
                                    color: #ED5C43;
                                    font-weight: bold;
                                    font-family: BebasNeueBold;
                                    line-height: 22px;
                                }
                                .s2{
                                    font-size: 40px;
                                }
                                .s3{
                                    position: relative;
                                    bottom: 2px;
                                }
                            }
                            p{
                                margin-left: 8px;
                                height: 32px;
                                border-radius: 4px;
                                border: 1px solid #267DFF;
                                color: #267DFF;
                                font-weight: bold;
                                font-size: 18px;
                                flex-grow: 0;
                                padding: 0 6px;
                                text-align: center;
                                line-height: 30px;
                                box-sizing: border-box;
                            }

                        }
                    }

                }

            }
        }
    }
}
.example-3d{
    margin-bottom: 104px;
    margin-bottom: calc(104px + env(safe-area-inset-bottom));
}
.bottom-btn{
    width: 750px;
    height: 104px;
    background: linear-gradient(237deg, #FFF100 0%, #FFDE00 100%);
    position: fixed;
    bottom: 0;
    left: 0;
    padding-bottom: env(safe-area-inset-bottom);
    z-index: 99;
    color: #242831;
    font-size: 32px;
    font-weight: bold;
    &.disabled{
        filter: grayscale(100%);
        color: #9aa1a9;
    }
}
.reward-box-o{
    position: relative;
    padding:94px 70px 0;
    width: 722px;
    box-sizing: border-box;
    overflow: hidden;
}
.reward-box{
    width: 582px;
    height: 794px;
    background: url("https://chaolucoachwebstatic.chaolu.com.cn/static/img/new-people-2022/popup_bg.png") no-repeat center center;
    background-size: 100%;
    padding: 202px 0 0;
    box-sizing: border-box;
    position: relative;
    .popup-icon{
        width: 220px;
        height: 220px;
        position: absolute;
        right: -94px;
        top: -94px;
    }
    .reward-tips{
        white-space: pre-line;
        text-align: center;
        font-size: 28px;
        color: #FFFFFF;
        line-height: 42px;
        font-weight: 500;
    }
    .boon-box{
        display: flex;
        height: 216px;
        width: 498px;
        margin: 46px auto 0;
        align-items: center;
        &>div{
            display: flex;
            justify-content: center;
        }
        &>div:first-child{
            width: 178px;
            flex-grow: 0;
            flex-shrink: 0;
            div{
                color: #ED5C42;
                font-family: BebasNeueBold;
                font-weight: bold;
                display: flex;
                align-items: flex-end;
                position: relative;
                top: 12px;
                span{
                    font-size: 40px;
                    position: relative;
                    top: -6px;
                }
                p{
                    font-size: 112px;
                    line-height: 0.8;
                }
            }
        }
        &>div:last-child{
            flex-grow: 1;
            flex-direction: column;
            padding-left: 32px;
            div{
                line-height: 44px;
                color: #3C454E;
                font-weight: bold;
                font-size: 32px;
            }
            p{
                margin-top: 12px;
                font-size: 24px;
                line-height: 24px;
                color: #6C727A;
            }
        }
    }
    .boon-btn{
        position: absolute;
        bottom: 48px;
        left: 32px;
        width: 518px;
        &>div{
            display: flex;
            justify-content: space-between;
        }
        .img1{
            width: 208px;
            height: 120px;
        }
        .img2{
            width: 322px;
            height: 120px;
        }
        .img-big{
            width: 518px;
            height: 120px;
        }
        .directlyNum{
            position: absolute;
            right: 0;
            top: -58px;
            width: 296px;
            height: 40px;
            background: #FF6E00;
            border-radius: 29px;
            color: #FFFFFF;
            font-size: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            &:after{
                content: '';
                width: 10px;
                height: 10px;
                position: absolute;
                bottom: -5px;
                left: 100px;
                background: #FF6E00;
                transform: rotate(45deg);
            }
        }
    }
}
.result-close{
    width: 60px;
    height: 60px;
    margin: 40px auto 0;
    display: block;
}


.card-item{
    width: 100%;
    height: 600px;
    background-color: #FFDE00;
}

.swipe-item-img {
    height: 420px;
    display: block;
}

.my-swipe {
    position: relative;
    img{
        width: 100%;
        display: block;
    }
    .customer {
        width: 112px;
        height: 48px;
        position: absolute;
        right: 0;
        top: 240px;
    }
}

.page-h {
    img {
        width: 750px;
    }
}

/deep/ .van-swipe__indicator--active {
    width: 24px !important;
    height: 8px !important;
    background: #FFDE00;
    border-radius: 5px;
}

/deep/ .van-swipe__indicator {
    width: 8px;
    height: 8px;
    background: #EEEEEE;
    opacity: 1;
}


// 选择城市门店弹窗
.city-select-box {
    height: 3rem;
    width: 100%;
    //padding-top: 1.0625rem;
    color: #6C727A;
    font-size: 0.875rem;
    display: flex;
    box-sizing: border-box;
    line-height: 0.875rem;
    align-items: center;
}

.icon-down {
    display: inline-block;
    width: 0.75rem;
    //vertical-align: middle;
    margin-left: 0.25rem;
}

.vam {
    font-size: 28px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #3C454E;
    line-height: 1;
}

.vam-store {
    font-size: 24px;
}

.bg-white {
    background: #fff;
}

.flex, .flex-between {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.flex-between {
    justify-content: space-between;
}

.empty {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0;
    flex-direction: column;

    img {
        width: 320px;
        height: 320px;
    }

    div {
        font-size: 28px;
        color: #6c727a;
        line-height: 44px;
        margin-top: 16px;
    }
}

// 海报样式
.share-poster-cont {
    position: relative;
    overflow: hidden;
    width: 574px;
    height: 818px;
    margin: 0 auto;
    box-sizing: border-box;
    border-radius: 8px;
    img.share-bg{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: block;
        z-index: 1;
    }
}
.share-user{
    display: flex;
    align-items: center;
    position: relative;
    z-index: 99;
    padding: 28px 0 0 24px;
    img{
        width: 52px;
        height: 52px;
        border-radius: 50%;
        margin-right: 12px;
    }
    .use-header-name{
        font-size: 22px;
        //font-weight: bold;
        color: #fff;
        line-height: 22px;
        //width: 45 * @w;
        width: 10em;
    }
    .use-header-text{
        font-size:22px;
        font-weight: 400;
        color: #BABBBB;
        line-height: 22px;
        margin-top: 8px;
    }
}
.poster-data{
    padding-left: 24px;
    margin-top: 60px;
    position: relative;
    z-index: 99;
    div{
        line-height: 84px;
        color: #FFFFFF;
        font-size: 84px;
        font-family: PuHuiTi;
    }
    p{
        margin-top: 6px;
        font-size: 24px;
        line-height: 24px;
        color: #EEEEEE;
    }
}
.share-poster-cont .poster-code-cont {
    z-index: 99;
    position: absolute;
    right: 24px;
    bottom: 48px;
    width: 140px;
    height: 140px;
    padding: 0;
    background: #fff;
    box-sizing: border-box;
    border-radius: 6px;
}

.share-poster-cont .poster-code-img {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    display: block;
    background-color: white;
    box-sizing: border-box;

}
.tip-box {
    position: relative;
    .tip-img {
        position: absolute;
        top: 7px;
        right: 130px;
        width: 300px;
        z-index: 99999;
        display: block;
    }
}
.rule-popup-box{
    width: 654px;
    box-sizing: border-box;
    padding-bottom: 24px;
    h3{
        text-align: center;
        padding: 32px 0;
    }
    .rule-popup-text{
        width: 100%;
        //min-height: 400px;
        max-height: 650px;
        display: block;
        padding: 0 48px;
        box-sizing: border-box;
        overflow-y: auto;
        font-size: 24px;
        font-weight: 400;
        color: #666666;
        line-height: 40px;
        white-space: pre-line;
    }
}
</style>
